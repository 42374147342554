.header {
    height: 80px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    padding: 10px 20px; 
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    position: fixed;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    max-width: 1200px; 
    width: 100%;
}

.header-logo {
    width: 150px; 
    height: 100px; 
    margin-right: 30px;
    object-fit: contain; 
}

.header-nav {
    flex: 1;
    text-align: center;
}

.header-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    padding-right: 150px;
}

.header-nav li {
    margin: 0 10px;
}

.header-nav li a {
    color: #333; 
    text-decoration: none;
    font-size: 18px;
}

.header-nav li a:hover {
    color: #ffcc00; 
}

.header-controls {
    display: flex;
    align-items: center;
    margin-left: auto; 
}

.cloudclou-text {
    font-family: 'Bezier Sans Bold', sans-serif;
    font-size: 36px; /* Adjust size as needed */
    font-weight: bold;
    color: #333;
    padding-left: 100px;
}

.header-contact-button {
    font-family: 'Bezier Sans';
    padding: 10px 20px;
    background-color: #0080ff;
    color: #ffffff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 20px;
    text-decoration: none; /* Ensure no underline */
    transition: background-color 0.3s ease, transform 0.1s ease; /* Add smooth transition */
}
.header-contact-button:hover {
    background-color: #0014ff;
    text-decoration: none; /* Ensure no underline on hover */
}

.header-contact-button:active {
    transform: scale(0.98); /* Slightly scale down on click, instead of changing margins */
}

@media (max-width: 768px) {
    .header-content {
        justify-content: space-between;
    }

    .header-nav {
        display: none;
        width: 100%;
    }

    .header-controls {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .cloudclou-text {
        display: none;
    }

    .header-controls-mobile {
        justify-content: center;
        width: 100%;
    }

    /* Reduced button size for mobile */
    .header-contact-button {
        padding: 8px 16px;  /* Reduce padding */
        font-size: 14px;    /* Reduce font size */
        border-radius: 15px; /* Adjust border-radius if needed */
    }

    .header-contact-button:active {
        transform: scale(0.98); /* Same behavior in mobile */
    }
}