@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    font-family: 'Bezier Sans Bold';
    src:
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    font-family: 'Bezier Sans Regular';
    src: 
         url('../../public/fonts/BezierSansRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }
  

.home {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8%;
    /*padding-left: 100px;
    padding-right: 100px;
    /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Тень снизу */
    

    /*height: calc(100vh - 80px); /* Высота экрана минус высота шапки */
}

.text-image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding-bottom: 20px;
    
    position: relative; /*margin: 20px 0; */
    background-color: #ffffff; /* Белый фон для контейнера */

    /*!!width: 2949.6px; /* Установлена фиксированная ширина */
    /*height: 560.4px; /* Установлена фиксированная высота */
    /* border-radius: 8px; /* Скругление углов */
}

.text-image-container::after {
    content: '';
    position: absolute;
    /*!!left: 0;
    right: 0;
    bottom: 0;
    height: 20px; /* Высота подсветки */
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8)); /* Градиент для подсветки */
    pointer-events: none; /* Предотвращаем взаимодействие с псевдоэлементом */
}
.text-main{
    font-family: 'Bezier Sans Bold';
}

.text-mini{
    font-family: 'Bezier Sans', sans-serif;
}

.text {
    flex: 1;
    padding: 10px;
    padding-left: 40px;
    font-size: 18px;
    line-height: 1.6;
    font-family: 'Bezier Sans', sans-serif;
    

    /* font-weight: 700;
    height: 281px; */
    text-align: center;

    /* Центрирование текста по вертикали, если необходимо */
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.image img {

    border-radius: 8px; /* Keeps the corners rounded */
}


.text-container {
    display: flex;
    /*!!justify-content: space-around;*/
    margin-top: 20px; /* Отступ сверху */
}

.text-line {
    text-align: center;
}

/* Медиа-запрос для мобильных устройств */
@media screen and (max-width: 768px) {
    .text-image-container {
        max-width: 300px;
        flex-direction: column; /* Столбцовое расположение кнопок на мобильных устройствах */
        align-items: center; /* Центрируем кнопки по центру */
    }

    .image {
        width: 80%; /* Уменьшаем ширину изображения */
        height: auto; /* Автоматически устанавливаем высоту */
        margin-top: 20px; /* Добавляем отступ сверху */
    }
    
    .image img {
        width: 100%; /* Изображение занимает всю ширину контейнера */
        height: auto; /* Высота сохраняет пропорции изображения */
        border-radius: 8px; /* Сохраняем скругленные углы */
    }

    .text {
        padding: 10px; /* Уменьшаем отступы текста */
        font-size: 16px; /* Уменьшаем размер шрифта */
    }
}
