@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    
    font-family: 'Bezier Sans Bold';
    src:
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }
.engineering {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100vh; /* Устанавливаем высоту контейнера равной высоте экрана */
    background-color: #dee9f5;
    
}

.engineering-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items at the top */
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px; /* Add space between image and text */
}

.engineering-image {
    flex: 1;
}

.engineering-image img {
    width: 100%;
    height: auto;
    max-width: 600px; /* Adjust as needed */
    border-radius: 8px; /* Optional: Add rounded corners */
}

.engineering-text {
    flex: 1;
    padding: 20px;
}

.main-text
{
    font-family: 'Bezier Sans Bold';
}

.engineering-text .main-text {
    font-size: 24px; /* Adjust font size as needed */
    line-height: 1.5;
    margin-bottom: 20px; /* Add space below the main text */
}

.engineering-text .additional-info {
    font-size: 18px; /* Adjust font size as needed */
    line-height: 1.6;
}

.engineering-text .additional-info p {
    font-size: 18px;
    line-height: 1.6;
}


@media (max-width: 768px) {

    .engineering {
        height: auto; /* Убираем фиксированную высоту для мобильной версии */
        flex-direction: column;
        padding: 20px; /* Добавляем отступы для мобильных устройств */
    }

    .engineering-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%; /* Контент будет занимать всю ширину */
    }

    .engineering-text {
        padding-left: 0; /* Убираем левый отступ */
        padding-bottom: 20px; /* Добавляем нижний отступ для пространства между текстом и изображением */
    }

    .engineering-image {
        margin-top: 20px; /* Add some space between text and image */
    }
    .engineering-image img {
        max-width: 80%; /* Reduce the image size to 80% of its container width */
    }


    .home5 {
        height: auto; /* Убираем фиксированную высоту для мобильной версии */
        flex-direction: column;
        padding: 20px; /* Добавляем отступы для мобильных устройств */
    }


}

