@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    font-family: 'Bezier Sans Bold';
    src:
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }

.services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh; /* Фиксированная высота для десктопной версии */
    box-sizing: border-box;/* Учитываем паддинги в расчете высоты */
}

.home4-content {
    display: flex;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}

.home4-image-container {
    flex: 1;
    text-align: center;
}


.home4-image-container img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.home4-text-container {
    flex: 1;
    padding-left: 30px;
}

.main-text
{
    font-family: 'Bezier Sans Bold';
}



.home4-text {
    flex: 1;
    padding: 20px;
}

.home4-image {
    flex: 1;
    display: flex;
    justify-content: center;
}



.main-text {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
}

.additional-info p {
    font-size: 18px;
    line-height: 1.6;
}
@media (max-width: 768px) {
    .home4-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%; /* Контент будет занимать всю ширину */
    }

    .home4-text {
        padding: 10px;
    }

    .home4-image {
        margin-top: 20px; /* Add some space between text and image */
    }
    .home4-image img {
        max-width: 80%; /* Reduce the image size to 80% of its container width */
    }

    .services {
        height: auto; /* Убираем фиксированную высоту для мобильной версии */
        flex-direction: column;
        padding: 20px; /* Добавляем отступы для мобильных устройств */
    }


    .home4-text-container {
        padding-left: 0; /* Убираем левый отступ */
        padding-bottom: 20px; /* Добавляем нижний отступ для пространства между текстом и изображением */
    }

    .home4-image-container {
        margin-top: 20px; /* Добавляем отступ сверху */
    }


}