/* src/styles/Home1.css */
@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    font-family: 'Bezier Sans Bold';
    src:
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }
  
.switchpage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}

.switchpage-buttons-container {
    display: flex;
    gap: 15px; /* Space between buttons */
}

.switchpage-button {
    height: 40px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Bezier Sans', sans-serif;
    background-color: #0080ff; /* Adjust color as needed */
    color: #fff;
    border: none;
    border-radius: 15px;
}

.switchpage-button:hover {
    background-color: #0014ff; /* Adjust hover color as needed */
}

@media (max-width: 768px) {

    .switchpage-buttons-container {
        flex-direction: column; /* Столбцовое расположение кнопок на мобильных устройствах */
        align-items: center;
        padding: 12px 12px;
        
    }
    .switchpage-button {
        padding: 12px 24px; /* Slightly larger padding for mobile devices */
        font-size: 14px; /* Increase font size for readability on smaller screens */
        height: auto;
        text-align: center; /* Center text within buttons */
    }
}