/* ContactUsPage.css */
@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  
    font-family: 'Bezier Sans Extra Bold';
    src: 
         url('../../public/fonts/BezierSansExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  
    font-family: 'Bezier Sans Bold';
    src: 
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  
    font-family: 'Bezier Sans Regular';
    src: 
         url('../../public/fonts/BezierSansRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }
.contact-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
}

.get-in-touch {
    font-family: 'Bezier Sans';
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}



@media (max-width: 768px){
    .get-in-touch{
        justify-content: center;
        align-items: center; 
        padding-left: 5%;
    }
}
