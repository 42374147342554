.bottom-container {
    display: flex;
    justify-content: space-between;
    /*margin-top: 50px; /* Add space before the container */
    padding: 20px;
    /*padding-bottom: 150px;*/
    /*background-color: #dee9f5;*/
    
}

.bottom-sub-container {
    flex: 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
}

.text-container-connection{
    padding-left: 20%;
    padding-right: 20%;
}

/* .text-container-connection {
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation 
}*/

/* .text-container-connection p {
    margin: 0;
    font-size: 16px;
    color:  
}*/

@media (max-width: 768px) {
    .bottom-container {
        flex-direction: column;
    }
    
    .bottom-sub-container {
        margin: 15px 0;
    }
}
