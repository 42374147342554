@font-face {
  font-family: 'Bezier Sans';
  src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
       url('../../public/fonts/BezierSans.woff') format('woff'),
       url('../../public/fonts/BezierSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Bezier Sans', sans-serif;
}

.form-container {
  padding-right: 20%;
  padding-left: 20%;
  width: 100%;
  max-width: 762px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.horizontal-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.label {
  font-family: 'Bezier Sans';
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 5px;
}

.label-error {
  color: #0080ff; /* Blue color for empty required fields */
}

.phone-input-container {
  width: 356.4px;
}

.phone-input-container .react-tel-input {
  width: 100%;
}

.phone-input-container input {
  font-family: 'Bezier Sans';
  height: 43.2px;
  border: 1px solid black;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0;
}

.text-window {
  border-radius: 15px;
  font-family: 'Bezier Sans';
  width: 100%;
  height: 44.8px;
  resize: none;
  border: 1px solid black;
  font-size: 16px;
  line-height: 1.5;
}

.email-window {
  width: 356.4px;
  height: 43.2px;
}

.message-window {
  height: 100px;
}

.necessary-note {
  font-family: 'Bezier Sans';
  font-weight: normal;
  font-size: 12px;
  color: #0080ff;
  margin-top: 5px;
}

.input-phone {
  font-family: 'Bezier Sans';
  width: 100%;
  height: 48.2px;
  border: 1px solid black;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 15px;
}

.contact-button {
  width: 622px;
  height: 38px;
  background-color: #0080ff;
  border-radius: 15px;
  border: 1px solid #0014ff;
  font-family: 'Bezier Sans';
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin: 20px auto 0; /* Center horizontally and add top margin */
  transition: background-color 0.3s, color 0.3s;
  align-self: center; /* Center within flex container */
}

.contact-button:hover {
  background-color: #ffffff;
  color: black;
}

.data-admin-note {
  font-family: 'Bezier Sans';
  font-size: 12px;
  color: #0080ff;
  margin-top: 20px;
  text-align: center;
  width: 100%; /* Ensure it takes full width */
  display: flex;
  justify-content: center; /* Center horizontally */
  padding-bottom: 20%;
}

.feedback {
  color: #0014ff;
  justify-content: center;
  align-self: center;
}

.horizontal-container .input-container:first-child {
  margin-right: 20px; /* Adjust the value as needed */
}

.error {
  border-color: red; /* Цвет границы для полей с ошибками */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}



/* Mobile styles */
@media (max-width: 768px) {
  .form-container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .label {
    padding-right: 10px;
    padding-left: 10px;
  }

  .horizontal-container {
    flex-direction: column; /* Stack vertically on mobile */
    align-items: flex-start; /* Align items to the start */
  }

  .input-container {
    width: 100%; /* Full width for input containers */
    padding: 0;
    margin-bottom: 15px; /* Add more spacing between inputs */
  }

  .text-window,
  .email-window,
  .input-phone {
    margin-left: 10px;
    width: 85%; /* Ensure phone input is the same width as other inputs */
    height: 48px; /* Increase height for better touch experience */
  }

  .phone-input-container {
    width: 100%; /* Ensure full width for the container */
  }

  .phone-input-container input {
    height: 48px; /* Match the height of other inputs */
  }

  .contact-button {
    width: 80%; /* Full width for the button */
    height: 48px; /* Increase button height */
  }

  .message-window {
    height: 150px; /* Increased height for mobile */
  }

  .data-admin-note {
    align-items: center;
    width: 80%;
    padding-left: 5%;
    padding-bottom: 150px; /* Increase padding for better spacing */
  }

  .elementstyle {
    width: 80%;
  }

  .input-phone {
    margin-left: 10px;
    width: 85%; 
     /* Ensure full width inside the reduced container */
  }
  .necessary-note {
    padding-left: 5px;
  }
}
