@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: 'Bezier Sans', sans-serif;
}

.home5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh; /* Фиксированная высота для десктопной версии */
    box-sizing: border-box;
    background-color: #dee9f5;
}

.home5-content {
    display: flex;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}

.home5-image-container {
    flex: 1;
    text-align: center;
}

.home5-image-container img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.home5-text-container {
    flex: 1;
    padding-left: 30px;
}

.home5-text-container .main-text {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.home5-text-container .additional-info p {
    font-size: 18px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .home5 {
        height: auto; /* Убираем фиксированную высоту для мобильной версии */
        flex-direction: column;
        padding: 20px; /* Добавляем отступы для мобильных устройств */
    }

    .home5-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%; /* Контент будет занимать всю ширину */
    }

    .home5-text-container {
        padding-left: 0; /* Убираем левый отступ */
        padding-bottom: 20px; /* Добавляем нижний отступ для пространства между текстом и изображением */
    }

    .home5-image-container {
        margin-top: 20px; /* Добавляем отступ сверху */
    }

    .home5-image-container img {
        max-width: 80%;
    }
}
