/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

/** {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}*/

body {
  /*display: flex;*/
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 100vh;
}

.footer {
  position: relative;
  /* background: linear-gradient(to right, #0014ff, #0080ff); /* Градиент от левого края к правому 3586ff*/
  background: #3586ff;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  align-items: flex-start; /* Align items at the start */
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  margin-right: 50px; /* Add space between the logo and the next column */
}

.footer-logo img {
  max-width: 150px;
  margin-bottom: 0; /* Remove bottom margin to align logo with the content next to it */
}

.menu2 {
  list-style: none;
  padding: 0;
}

.menu__item {
  margin-bottom: 10px;
}

.menu__link  {
  font-size: 1.1rem;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.75;
  transition: opacity 0.5s;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}


.footer p {
  color: #ffffff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}



.partners-logos  ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.partners-logos img {
  max-width: 50px;
  border-radius: 20px;
  flex: auto;
}


.wave#wave1 {
  z-index: 999;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 15s linear infinite; /* Было 4s */
}

.wave#wave2 {
  z-index: 998;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 15s linear infinite !important; /* Было 4s */
}

.wave#wave3 {
  z-index: 999;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 10s linear infinite; /* Было 3s */
}

.wave#wave4 {
  z-index: 998;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 10s linear infinite; /* Было 3s */
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
    align-items: center; /* Выравнивание по центру */
    text-align: center;  /* Центрирование текста */
  }

  .footer-logo {
    margin-right: 0; /* Убрать отступ справа у логотипа */
    margin-bottom: 20px; /* Добавить отступ снизу */
  }

  .footer-logo img {
    max-width: 120px; /* Уменьшить размер логотипа */
  }

  .menu__link {
    font-size: 1rem; /* Уменьшить размер шрифта */
  }

  .menu__item p {
    font-size: 0.9rem; /* Уменьшить размер текста для информации */
  }

  .footer p {
    font-size: 0.9rem; /* Уменьшить размер текста в футере */
  }

  .partners-logos ul {
    justify-content: center;
  }

  .partners-logos img {
    max-width: 40px; /* Уменьшить размер логотипов партнеров */
  }


}
