@font-face {
    font-family: 'Bezier Sans';
    src: url('../../public/fonts/BezierSans.woff2') format('woff2'),
         url('../../public/fonts/BezierSans.woff') format('woff'),
         url('../../public/fonts/BezierSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

    font-family: 'Bezier Sans Bold';
    src:
         url('../../public/fonts/BezierSansBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bezier Sans', sans-serif;
  }
/* Ваши стили */

.container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; /* Высота экрана целиком */
}

.container {
    width: 100%;
    height: 100%;
    padding-bottom: 5%;
    /*padding: 80px 0px 64px;*/
    background-color: #ffffff;
    margin: 0 auto; /* Центрируем контейнер по горизонтали */
    border-radius: 20px;
    display: flex;
    position: relative; /* Для позиционирования псевдоэлемента */
}

.container::before {
    content: '';
    position: absolute;
    /* bottom: 0; /* Подсветка снизу */
    /* left: 0; */
    width: 100%;
    height: 20px; /* Высота подсветки */
    box-sizing: border-box; /* Учитываем border в размерах */
    pointer-events: none; /* Предотвращаем взаимодействие с псевдоэлементом */
}

.partners {

    margin-top: 20px;
    padding: 20px;
    width: 100%; /* Занимаем всю доступную ширину контейнера .container */
    box-sizing: border-box; /* Учитываем padding в общей ширине */
    text-align: center; /* Выравниваем текст по центру */
}

.text-container {

    justify-content: center; /* Центрируем контент горизонтально */
    align-items: center;
    padding-top: 30px;
    margin: 0 auto;
    margin-bottom: 32px; /* Отступ от текста 1174x32 */
}

.text-40,
.text-30,
.text-20 {
    font-family: 'Bezier Sans', sans-serif;

}

.text-40 {
    font-family: 'Bezier Sans Bold';
    padding-top: 8%;
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 60px;
}



.text-20 {
    font-family: 'Bezier Sans', sans-serif;
    justify-content: left; 
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

.padding-button {
    padding: 20px;
}

.buttons-container {
    font-family: 'Bezier Sans', sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%; 
    margin: 0 auto;
    margin-top: 20px;
 /* Равномерное распределение между кнопками */
    padding-bottom: 10%;
}

.button-image{
    margin-top: 5px;
    width: 100px; /* Задайте нужные размеры */
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.partner-description {
    font-family: 'Bezier Sans', sans-serif;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 1.5;
}

.partner-text-k{
    font-family: 'Bezier Sans', sans-serif;
    font-size: 14px;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 5%;
}

.buttons-container button {
    width: 390px; /* 376.03pxШирина кнопки */
    height: 400px; /*457.2px Высота кнопки */
    padding: 0; /* Убираем внутренние отступы */
    font-size: 16px;
    background-color: white; /* Белый фон */
    color: black; /* Черный текст */
    border: 1px solid #0014ff; /* Черная обводка */
    cursor: pointer;
    box-sizing: border-box; /* Учитываем padding и border в общей ширине */
    overflow: hidden; /* Обрезаем содержимое, выходящее за пределы кнопки */
    position: relative; /* Позиционируем внутренние элементы относительно кнопки */
    /*font-family: 'Bezier Sans Bold';*/
    font-weight: bold;
    border-radius: 40px;
}

.buttons-container button:hover {
    background-color: #0014ff; /* Темно-серый фон при наведении */
    color: white; /* Белый текст при наведении */
}

.partner-button {

    width: 772.03px;
    height: 51px;
    margin: 20px auto 0; /* Отступ снизу для разделения кнопок */
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0080ff;/* Светло-серый фон */
    color: rgb(246, 253, 255); /* Черный текст */
    border: none; /* Убираем обводку */
    cursor: pointer;
    box-sizing: border-box; /* Учитываем padding и border в общей ширине */
    font-weight: bold;
    border-radius: 30px;
}

/* Media query для мобильных устройств */
@media screen and (max-width: 768px) {

    .partners {
        padding-bottom: 20%;
        height: auto;
    }
    .padding-button {
        flex-direction: column; /* Столбцовое расположение кнопок на мобильных устройствах */
        align-items: center; /* Центрируем кнопки по центру */
        margin-top: 20px;
        width: calc(100% - 30px); /* Полная ширина кнопок на мобильных устройствах с небольшим отступом */
        max-width: 300px; /* Максимальная ширина кнопок, чтобы не были слишком широкими */
        height: auto; /* Увеличенная высота для мобильных устройств */
        font-size: 24px; /* Увеличенный размер шрифта для лучшей читаемости */
        padding: 20px;
        font-weight: bold;
    }

    .text-40,
    .text-30,
    .text-20 {
        /*font-family: 'BezierSans', sans-serif;*/
        font-weight: bold;
        max-width: 100%;
    }

    .buttons-container {
        flex-direction: column; /* Столбцовое расположение кнопок на мобильных устройствах */
        align-items: center; /* Центрируем кнопки по центру */
    }

    .buttons-container button {

        margin-top: 20px;
        /*width: calc(100% - 40px); /* Полная ширина кнопок на мобильных устройствах с небольшим отступом */
        max-width: 300px; /* Максимальная ширина кнопок, чтобы не были слишком широкими */
        height: auto; /* Увеличенная высота для мобильных устройств */
        font-size: 24px; /* Увеличенный размер шрифта для лучшей читаемости */
        padding: 20px; /* Увеличенный отступ для лучшей зоны нажатия */
        font-family: 'BezierSans', sans-serif;

    }

    .partner-button {
        /*width: calc(100% - 30px); /* Полная ширина кнопки "партнёр" на мобильных устройствах с небольшим отступом */
        max-width: 300px; /* Максимальная ширина кнопки "партнёр", чтобы не была слишком широкой */
        height: 70px; /* Увеличенная высота кнопки "партнёр" на мобильных устройствах */
        font-size: 18px; /* Увеличенный размер шрифта для лучшей читаемости */
        padding: 15px 20px; /* Увеличенный отступ для лучшей зоны нажатия */
        font-family: 'BezierSans', sans-serif;

    }

    
}
